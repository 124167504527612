<template>
  <div
    ref="observerEl"
    class="SpaceHandler"
    :class="[spacingClasses]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
/**
 * It seems we can't type this since storyblok thinks it's an SbDataBlok
 */
const props = defineProps<{
  blok: any,
}>();

const spacingClasses = computed(() => {
  const output = [];
  if (props.blok?.visualAppearance?.spacing) {
    output.push(props.blok.visualAppearance.spacing);
  } else {
    output.push('spacing-base');
  }
  return output;
});

</script>

<style scoped lang="postcss">
</style>
